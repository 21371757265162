import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import useMedia from 'use-media'
import classnames from 'classnames'
import { Columns, Column } from 'react-flex-columns'
import Icon from 'shared/ui/Icon'
import theme from 'shared/styles/theme'

const subscriptionPlanSummaryStyles = css`
  border-bottom: 1px solid ${theme.lineColor};

  &.selectable .subscription-plan-summary-highlight {
    cursor: pointer;

    &:hover:not(.selected) {
      background-color: ${theme.lightGreen};
    }
    &.selected {
      background-color: ${theme.lightBlue};
      outline: 1px solid ${theme.brightBlue};
    }
  }
`
const planNameStyles = css`
  font-family: 'Baloo Chettan';
  color: ${theme.brightBlue};
`
const pricingStyles = css`
  .amount {
    font-size: 2.4em;
    font-weight: bold;
  }
  .amount-unit {
    font-size: 0.8em;
    font-weight: bold;
  }
`

function SubscriptionPlanSummary({ children, name, selectable, selected, onSelect, price, usageBased }) {
  const stack = useMedia({ maxWidth: '700px' })

  return (
    <div css={subscriptionPlanSummaryStyles} className={classnames({ selectable })} role="button" onClick={onSelect}>
      <div className={classnames('subscription-plan-summary-highlight', { selected })}>
        <Columns middle stack={stack}>
          {!stack && <Column size={3}>{selected && <Icon icon="caret-right" size={2} color="blue" />}</Column>}
          <Column size={11}>
            <div css={planNameStyles}>{name}</div>
            {usageBased ? (
              <div css={pricingStyles}>
                <strong className="text-large">Usage Based</strong>
              </div>
            ) : (
              <div css={pricingStyles}>
                <span className="amount">${price / 100}</span>
                {price > 0 && <span className="amount-unit">/mo</span>}
              </div>
            )}
          </Column>
          <Column flex>{children}</Column>
        </Columns>
      </div>
    </div>
  )
}

SubscriptionPlanSummary.defaultProps = {
  selectable: false,
  selected: false,
  usageBased: false,
}

SubscriptionPlanSummary.propTypes = {
  name: PropTypes.string.isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  price: PropTypes.number,
  usageBased: PropTypes.bool,
}

export default SubscriptionPlanSummary
