import React from 'react'
import PropTypes from 'prop-types'
import SubscriptionPlanSummary from 'shared/ui/SubscriptionPlanSummary'
import { IconList, IconListItem } from 'shared/ui/IconList'

class SubscriptionPlanSummaryAll extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      plans: [{ planId: 3, price: 16500 }, { planId: 4, price: 36500 }],
    }
    this.getPlanPrice = this.getPlanPrice.bind(this)
  }

  getPlanPrice(planId) {
    const plan = this.state.plans.find(plan => plan.planId === planId)
    return plan.price
  }

  render() {
    const { subscriptionPlanId, selectPlan } = this.props
    const selectable = selectPlan.length > 0

    return (
      <div>
        <SubscriptionPlanSummary
          selectable={selectable}
          onSelect={() => selectPlan(1)}
          name="14-Day Free Trial"
          price={0}
          selected={subscriptionPlanId === 1}>
          <IconList>
            <IconListItem icon="check-circle-solid" color="green">
              Access to <strong>Popular Professional Tests</strong>
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              Access to <strong>All Features</strong>
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              5 Test Sessions During Trial
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              Only Available Upon Signup
            </IconListItem>
          </IconList>
        </SubscriptionPlanSummary>
        <SubscriptionPlanSummary
          selectable={selectable}
          name="Pay-As-You-Go"
          usageBased
          onSelect={() => selectPlan(2)}
          selected={subscriptionPlanId === 2}>
          <IconList>
            <IconListItem icon="check-circle-solid" color="green">
              Access to <strong>Popular Professional Tests</strong>
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              Access to <strong>All Features</strong>
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              3 Test Sessions Per Month For Free
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              Pay $10 Per Session After First 3
            </IconListItem>
          </IconList>
        </SubscriptionPlanSummary>
        <SubscriptionPlanSummary
          selectable={selectable}
          name="Business"
          price={this.getPlanPrice(3)}
          onSelect={() => selectPlan(3)}
          selected={subscriptionPlanId === 3}>
          <IconList>
            <IconListItem icon="check-circle-solid" color="green">
              Access to <strong>All Professional Tests</strong>
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              Access to <strong>All Features</strong>
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              40 Test Sessions Per Month (Included)
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              Pay $3 Per Session After 40
            </IconListItem>
          </IconList>
        </SubscriptionPlanSummary>
        <SubscriptionPlanSummary
          selectable={selectable}
          name="Executive"
          price={this.getPlanPrice(4)}
          onSelect={() => selectPlan(4)}
          selected={subscriptionPlanId === 4}>
          <IconList>
            <IconListItem icon="check-circle-solid" color="green">
              Access to <strong>All Professional Tests</strong>
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              Access to <strong>All Features</strong>
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              150 Test Sessions Per Month (Included)
            </IconListItem>
            <IconListItem icon="check-circle-solid" color="green">
              Pay $2 Per Session After 150
            </IconListItem>
          </IconList>
        </SubscriptionPlanSummary>
        <p className="text-small">
          For all monthly plans listed above, "Access" means "Subscription Access" not "Full Access". Please{' '}
          <a href="https://questionable.io/professional-tests">review and understand the differences.</a>
        </p>
      </div>
    )
  }
}

SubscriptionPlanSummaryAll.defaultProps = {
  subscriptionPlanId: 0,
  selectPlan: () => null,
}

SubscriptionPlanSummaryAll.propTypes = {
  subscriptionPlanId: PropTypes.number,
  selectPlan: PropTypes.func,
}

export default SubscriptionPlanSummaryAll
