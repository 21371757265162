import React, { Fragment } from 'react'
import Link from 'gatsby-link'
import { useMediaLayout } from 'use-media'
import { Columns, Column } from 'react-flex-columns'
import DocumentLayout from '../DocumentLayout'
import Centered from '../Centered'
import SubscriptionPlanSummaryAll from '../shared/ui/SubscriptionPlanSummaryAll'

function Pricing() {
  const stack = useMediaLayout({ maxWidth: '1100px' })

  return (
    <DocumentLayout className="subpage-white">
      <Centered className="panel-spacing" spacing>
        <br />
        <div className="spacing">
          <p className="text-large">How does our pricing work?</p>
          <hr />

          <Columns gutters stack={stack}>
            <Column size={33} className="spacing">
              <p>
                New accounts will start in trial mode which gives access to popular tests and the ability to start 5
                testing sessions within 14 days. At any point during or after trial, new accounts can migrate to any of
                our other plans.
              </p>
              {!stack && (
                <Fragment>
                  <h3>All Plans Include:</h3>
                  <ul className="spacing text-small">
                    <li>
                      <strong>Reports and Analytics</strong>
                      <br />
                      In addition to seeing the test taker results and score, see Gap Analysis reports on what
                      sub-topics the test taker struggled with the most.
                    </li>
                    <li>
                      <strong>Professional and Custom Questions</strong>
                      <br />
                      Use our professional questions and/or add your own. You can even edit our questions for your use
                      when having <Link to="/professional-tests">Full Access</Link> to our tests.
                    </li>
                    <li>
                      <strong>Automatic Updates</strong>
                      <br />
                      Receive updates automatically when questions are improved, added, or depreciated.
                    </li>
                    <li>
                      <strong>Compose New Tests</strong>
                      <br />
                      Get <Link to="/professional-tests">Full Access</Link> to our Professional Tests and you'll be able
                      to make new tests from any combination of questions you receive from us or you write yourself.
                    </li>
                    <li>
                      <strong>Sharable Invite Links</strong>
                      <br />
                      Create URL links to give out to test takers for easy registration. Test settings can even be
                      customized on a per-invite basis.
                    </li>
                    <li className="spacing-small">
                      <strong>Cheat Prevention</strong>
                      <ul className="spacing">
                        <li>Question content cannot be copied during test taking.</li>
                        <li>
                          Enable Webcam monitoring to take snapshots of the test taker periodically while they test
                          (with their permission).
                        </li>
                        <li>
                          Review the number of times the test taker "lost focus" on the test (left the browser tab).
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Quality Check</strong>
                      <br />
                      Our content is created by top industry experts in each field that we create tests for. Each test
                      is very modern and checked against a strict guideline system.
                    </li>
                  </ul>
                </Fragment>
              )}
            </Column>
            <Column className="spacing">
              <SubscriptionPlanSummaryAll />
              <div className="right-content">
                <Link to="/signup" className="action-button">
                  Signup
                </Link>
              </div>
            </Column>
          </Columns>
        </div>
      </Centered>
    </DocumentLayout>
  )
}

export default Pricing
